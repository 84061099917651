import React, { useState } from "react";
import Layout from "../components/layout";
import "/resources/app.scss";
import AppContext from "../components/AppContext";
import { Router } from "next/router";

export default function MyApp({ Component, pageProps }) {
  const [showLoader, setShowLoader] = useState(false);
  const [sidebarStatus, setSidebarStatus] = useState(false);
  const themes = { sidebarStatus, setSidebarStatus, showLoader, setShowLoader };
  // Use the layout defined at the page level, if available
  const getLayout =
    Component.getLayout ||
    ((page) => {
      return <Layout>{page}</Layout>;
    });


  Router.events.on('routeChangeStart', () => {
    setShowLoader(true);
  });

  Router.events.on('', () => {
    setShowLoader(false);
  });

  Router.events.on('routeChangeComplete', () => {
    setShowLoader(false);
  });

  Router.events.on('routeChangeError', () => {
    setShowLoader(false);
  });

  return (
    <AppContext.Provider value={themes}>
      {getLayout(<Component {...pageProps} />)}
    </AppContext.Provider>
  );
}
