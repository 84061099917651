import Link from "next/link";
import { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import AppContext from "./AppContext";

export default function Navbar({ children }) {
  const themeContext = useContext(AppContext);
  const logoutAction = async function () {
    await localStorage.removeItem("access_token");
    await localStorage.removeItem("user");
    window.location.assign("/login");
  }

  return (
    <>
      <nav className="sb-topnav navbar navbar-expand navbar-dark sb-sidenav-dark shadow">
        <Link className="navbar-brand p-3" href="/">
          Pinknblu
        </Link>
        <button
          className="btn   order-1 order-lg-0"
          onClick={() => { themeContext.setSidebarStatus(!themeContext.sidebarStatus) }}
        >
          <i className="bi bi-list"></i>
        </button>
       
        <Dropdown className="ms-auto me-2">
          <Dropdown.Toggle className="btn bg-transparent" variant="light" size="sm">
            <i className="bi bi-person-circle"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item key={'navbar-profile'} href="/profile"><i className="bi bi-person-circle" /> Profile</Dropdown.Item>
           <Dropdown.Divider/>
            <Dropdown.Item key={'navbar-logout'} onClick={logoutAction}><i className='bi bi-box-arrow-left' /> Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

      </nav>
    </>
  );
}
