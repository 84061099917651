export default [
    { key: "dashboard", href: "/", title: "Dashboard", icon: "bi bi-speedometer2" },
    { key: "orders", href: "/orders", title: "Orders", icon: "bi bi-list-ol", permissions: ['view', 'add', 'update', 'delete'] },
    { key: "products", href: "/products", title: "Products", icon: "bi bi-boxes", permissions: ['view', 'add', 'update', 'delete'] },
    { key: "settings", href: "/settings", title: "Settings", icon: "bi bi-gear", permissions: ['view', 'add', 'update', 'delete'] },
    
    /* { key: "user", href: "/users", title: "Users", icon: "bi bi-people", permissions: ['view', 'add', 'update', 'delete'] },
    { key: "payouts", href: "/payouts", title: "Payouts", icon: "bi bi-currency-rupee", permissions: ['view', 'add', 'update', 'delete'] },
    { key: "team", href: "/teams", title: "Team", icon: "bi bi-person", permissions: ['view', 'add', 'update', 'delete'] },
    { key: "role", href: "/roles", title: "Role & Permission", icon: "bi bi-key", permissions: ['view', 'add', 'update', 'delete'] },
 */]